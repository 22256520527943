<template>
  <div class="playlist-wrapper">
    <div class="title-wrapper">
      <div class="title-content">
        <h1>Please select a Playlist</h1>
      </div>
      <div class="title-content" style="cursor: pointer;" @click="getPlaylists">
        <h1><b-icon icon="arrow-clockwise" :style="{ color: loading ? 'grey' : 'black' }"></b-icon></h1>
      </div>
    </div>
    <div v-for="p in playlists" :key="p.id" class="playlist" @click="selectPlaylist(p)">
      <img :src="getImage(p)" :alt="p.name" class="playlist-image">
      <div class="playlist-title">
        <span>{{p.name}}</span>
        <br>
        <span style="font-weight: normal;">{{p.tracks.total}} Tracks</span>
      </div>
    </div>
    <div v-if="playlists.length === total" class="playlist">
      <img :src="getImage({})" alt="no image" class="playlist-image">
      <div class="playlist-title" style="opacity: 100%">
        <span>no more playlists</span>
        <br>
        <span style="font-weight: normal;">try reloading if there should be more</span>
      </div>
    </div>
    <IntersectionObserver sentinal-name="navScrollObserver" @on-intersection-element="loadMore()"/>
  </div>
</template>

<script>
import IntersectionObserver from '@/components/IntersectionObserver.vue'

export default {
  name: 'SelectPlaylists',
  data() {
    return {
      playlists: [],
      total: 0,
      loading: true,
      next: null,
    };
  },
  components: {
    IntersectionObserver,
  },
  mounted() {
    this.getPlaylists();
  },
  methods: {
    getPlaylists() {
      this.axios.get('https://api.spotify.com/v1/me/playlists', {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.authDetails.access_token}`
        }
      }).then((res) => {
        this.playlists = res.data.items;
        this.next = res.data.next;
        this.total = res.data.total,
        this.loading = false;
      });
    },
    selectPlaylist(p) {
      this.$store.commit('game/SET_PLAYLIST', p.id);
      this.$router.push('/device');
    },
    loadMore() {
      if (this.loading) return;
      if (this.playlists.length >= this.total) return;
      this.loading = true;
      this.axios.get(this.next, {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.authDetails.access_token}`
        }
      }).then((res) => {
        this.playlists = [...this.playlists, ...res.data.items];
        this.next = res.data.next;
        this.loading = false;
      });
    },
    getImage(p) {
      return p.images && p.images[0] && p.images[0].url || 'https://t3.ftcdn.net/jpg/04/23/89/98/360_F_423899840_2x7wg6gjpXej4iXHruiWJcLJAzJgSD9L.jpg'
    }
  }
}
</script>

<style scoped>

.playlist-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 5rem 0;
  flex-wrap: wrap;
}

.title-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  
}
.title-content {
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
}

.playlist {
  position: relative;
  display: flex;
  width: 20%;
  height: 0;
  padding-bottom: 20%;
  margin: 3rem;
  cursor: pointer;
}

.playlist-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.playlist-title {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0%;
  transition: 0.25s;
  padding: 10%;

  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-weight: bold;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.playlist-title:hover {
  opacity: 100%;
  transition: 0.25s;
}

</style>